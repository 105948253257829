import React from 'react'
import Helmet from 'react-helmet'
import HeroHome from '../components/hero-home'
import BoxAree from '../components/box-aree'
import BoxNews from '../components/box-news'
import PlaceHolder from '../components/placeholder'
//import heroimg from '../../static/images/contents/hero-homepage.jpg'
// import heroimg from '../../static/images/contents/gatto.jpg'
import { graphql, Link } from "gatsby"
import Img from 'gatsby-image'
import Layout from '../components/layout'
import FeaturedNews from '../components/featured-news'

const aree = [
  {
    "title": "Opportunità lavorative",
    "abstract": "L'Ospedale Veterinario dell'Università di Milano offre servizi specializzati e di avanguardia per la cura delle diverse specie animali",
    "img": "/images/contents/ico-doctor.png",
    "link": "/opportunita-lavorative/"
  },
  {
    "title": "Area Studenti",
    "abstract": "Come Ospedale Universitario offriamo agli studenti di studiare sul campo attraverso un percorso unico",
    "img": "/images/contents/ico-student.png",
    "link": "/area-studenti/"
  },
  {
    "title": "Area Enti",
    "abstract": "Scopri i servizi dedicati agli enti convenzionati con l'Ospedale",
    "img": "/images/contents/ico-bear.png",
    "link": "/area-enti/"
  }
];



class IndexPage extends React.Component {
  
  render() { 
    
    
    const hero = this.props.data.hero;
    const data = this.props.data.serviziJson;
    const news = this.props.data.news.edges;
    const bannerEsotici = this.props.data.bannerEsotici;
    const stickeyNews = this.props.data.sitckynews.edges;
    const allNews = [...stickeyNews, ...news];
   
    const datahero = {
      "title": "Ospedale Veterinario Universitario",
      "subtitle": "Diagnosi, Cura e Formazione",
      "img": hero,
      "arealink": true,
      "heading":true
    };
    const avvisoEvidenza = this.props.data.contentfulAvvisoEvidenza;
    

    return (
    <Layout location={this.props.location}>
       <Helmet
           bodyAttributes={
            { class :'has-hero'}
          }
          meta={[
            { property: 'og:site_name', content: 'Ospedale veterinario universitario di Lodi'},
            { property: 'og:type', content: 'website' },
            { property: 'og:title', content: `Ospedale veterinario universitario di Lodi` },
            { property: 'og:description', content:  `L'Ospedale Veterinario dell'Università di Milano offre servizi specializzati e di avanguardia per la cura delle diverse specie animali`},
            { property: 'og:image', content: `${process.env.NODE_ENV === 'production'?'https://www.ospedaleveterinario.unimi.it':''}${datahero.img.src}`}
          ]}
        />

        {avvisoEvidenza && avvisoEvidenza.visibile && <FeaturedNews data={avvisoEvidenza} />}
        
        <HeroHome data={datahero}/>

        <BoxAree data={aree}/>
        
        <div className="container">
          <div className="row">
            <div className="col-12">
            
              <section id="servizi-clinici" className="section"  aria-labelledby="region1">
                <h1 id="region1" className="section__title">Reparti</h1>
                <div className="reparti-grid">
                  {data.reparti.map((item,index) => 
                   
                      <Link to={item.link} title={item.title} className="reparti-grid__item">
                        <img src={item.img} alt={item.title} className="reparti-grid__img" />
                        <div className="reparti-grid__title">{item.title}</div>
                      </Link>  
                   
                  )}
                </div>
              
              </section>
            </div>
          </div>
        </div>
        
      

       {/*  <section id="news-related" className="section section--dark"  aria-labelledby="region3">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 id="region3" className="section__title">Emergenza Coronavirus: Regole e Comportamenti<Link to="/covid/" title="Vedi tutte" className="btn-view-all">Vedi tutte</Link></h1>
              </div>
            </div>
            <div className="row">
              {covid.slice(0, 3).map((item,index) => 
              <div key={index} className="col-md-4">
                <BoxNews data={item}/>
              </div>
              )}
            </div>
          </div>
        </section>  */}

        <section id="news-related" className="section section--dark"  aria-labelledby="region4">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 id="region4" className="section__title">News ed Eventi<Link to="/news-ed-eventi/" title="Vedi tutte" className="btn-view-all">Vedi tutte</Link></h1>
              </div>
            </div>
            <div className="row">
              {allNews.slice(0, 3).map((item,index) => 
              <div key={index} className="col-md-4">
                <BoxNews data={item}/>
              </div>
              )}
            </div>
          </div>
        </section>   
    </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`{
  serviziJson {
    diagnostici{
      default {
        title
        subtitle
        img
        link
      }
    }
    reparti {
      title
      img
      link
    }
  }
  news: allContentfulNewsEdEventi(limit: 3, 
    sort: {fields: [data], order:DESC }, 
    filter: {argomento: {ne:"Rassegna stampa" },inEvidenza: {ne: true}, node_locale: {eq: "it-IT"}} ) {
    edges {
      node {
        titolo
        friendlyUrl
        argomento
        data
        abstractNews {
          childMarkdownRemark {
            html
          }
        }
        immagine {
          file {
            url
          }
        }
      }
    }
  }
  sitckynews: allContentfulNewsEdEventi(limit: 3, 
    sort: {fields: [data], order:DESC }, 
    filter: {argomento: {nin: ["Covid","Rassegna stampa"]} ,inEvidenza: {eq: true}, node_locale: {eq: "it-IT"}} ) {
    edges {
      node {
        titolo
        friendlyUrl
        argomento
        data
        abstractNews {
          childMarkdownRemark {
            html
          }
        }
        immagine {
          file {
            url
          }
        }
      }
    }
  }
  covid: allContentfulNewsEdEventi(limit: 3, 
    sort: {fields: [data], order:DESC }, 
    filter: {argomento: {eq:"Covid" }, node_locale: {eq: "it-IT"}} ) {
    edges {
      node {
        titolo
        friendlyUrl
        argomento
        data
      }
    }
  }
  hero: contentfulHeroHome(contentful_id: {eq: "3IKSlCPedTmw8Dd0VDxI8h"}) {
    slides {
      titolo
      sottotitolo
      link
      immagine {
        title
        fluid(maxHeight: 620) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      immagineMobile {
        title
        fluid(maxWidth:640) {
          src
        }
      }
    }
  }
  
  bannerEsotici: file(relativePath: {eq: "banner-esotici.png"}) {
    childImageSharp{
      fluid(maxWidth: 600){
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  contentfulAvvisoEvidenza{
    titolo
    testo
    link
    visibile
  }
}
`