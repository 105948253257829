import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'


const minSwipeDistance = 50; 
class HeroHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      startX: 0,
      endX: 0
    }
  }

  handleTouchStart(touchStartEvent) {
    //this.setState({startX: touchStartEvent.targetTouches[0].clientX, endX: null})
    this.setState({startX: touchStartEvent.clientX, endX: null})
  }
  
  handleTouchMove(touchMoveEvent) {
    //this.setState({endX: touchMoveEvent.targetTouches[0].clientX})
    this.setState({endX: touchMoveEvent.clientX})
  }
  
  handleTouchEnd() {
    const {startX: touchStart, endX:touchEnd } = this.state;
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe && this.state.currentIndex !== this.props.data.img.slides.length-1) {
      this.moveRihgt();
    } else if (isRightSwipe && this.state.currentIndex !== 0) {
      this.moveLeft();
    }
  }

  moveLeft() {
    this.setState({
      currentIndex: this.state.currentIndex-1,
    })
  }

  moveRihgt() {
    this.setState({
      currentIndex: this.state.currentIndex+1,
    })
  }

  render() { 
    const data = this.props.data;
    const { slides } = data.img || []; 

    let {
      currentIndex
    } = this.state
    return (
      <div className={!!data.arealink?"hero-home":"hero-home hero-home--nolinkarea"}>
        <div className="hero-home__carousel-container" onPointerDown={touchStartEvent => this.handleTouchStart(touchStartEvent)}
onPointerMove={touchMoveEvent => this.handleTouchMove(touchMoveEvent)}
onPointerUp={() => this.handleTouchEnd()}>
          {slides.map((item,index) =>
            <div  key={index}  className="hero-home__carousel-item"  style={{transform: `translate(-${currentIndex * 100}%)`}}>
              <div className="hero-home__carousel-image-wrapper">
                <Img className="d-none d-sm-block" fluid={item.immagine.fluid} alt={data.title} />
                <Img className="d-sm-none" fluid={item.immagineMobile.fluid} alt={data.title} />
                <div className="hero-home__carousel-controls">
                  <button disabled={currentIndex===0} onClick={(e) => this.moveLeft()}> <i
                    className="fa fa-angle-left"
                    aria-hidden="true"/>
                  </button>
                  <button disabled={currentIndex === slides.length-1} onClick={(e) => this.moveRihgt()}><i
                    className="fa fa-angle-right"
                    aria-hidden="true"/></button>
                  
                </div> 
              </div>      
              <div className="hero-home__carousel-text-wrapper">
                <div className="container">
                    <div className="hero-home__carousel-text">
                      <div className="hero-home__carousel-title">{item.titolo}</div>
                      {!!item.sottotitolo && <span>{item.sottotitolo}</span>}
                      {!!item.link && (item.link.startsWith("http") ? <a target="_blank" rel="noopener" href={item.link} title={item.titolo}>Scopri di più</a>: <Link title={item.titolo} to={item.link}>Scopri di più</Link>)}
                    </div> 
                </div>
              </div>
            </div>
          )} 
           
            
      
        </div>
        

        {!!data.arealink && data.arealink &&
          <div className="hero-home__link">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-10 offset-md-1">
                  <div className="hero-home__btn-container">
                    <div className="hero-home__btn">

                      <Link to="/emergenza/" title="Hai una emergenza?" className="btn-link">
                      <img src="/images/contents/icona_emergenza.png" alt="Hai una emergenza?" />
                        Hai una emergenza?
                      </Link>
                      <div className="d-none d-sm-block mt-3"><em>Pronto soccorso h24</em></div>
                    </div>
                    <div className="hero-home__btn">
                      <Link to="/prenota-visita/" title="Prenota una visita" className="btn-link">
                      <img src="/images/contents/icona_stethoscope.png" alt="Prenota una visita"  />

                        Prenota una visita</Link>
                        <div className="d-none d-sm-block mt-3"><em>Informazioni e prenotazioni</em></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        } 


      </div>
    )
  }
}

export default HeroHome