import React from 'react'
import { Link } from 'gatsby'

class BoxAree extends React.Component {

  render() { 
    const data = this.props.data;
    return (
      <div className="box-aree">
        <div className="container">
          <div className="row">
            { data.map((item,index) => {
              return   <div key={index} className="col-md-4">
                <div className="box-aree__item">
                  <h2 className="box-aree__title">
                    <Link to={item.link} title={item.title} className="box-aree__link box-aree__link--ico" 
                       style={{backgroundImage: `url(${item.img})`}}>{item.title}</Link>
                  </h2>
                  <p>{item.abstract}</p>
                </div>
              </div>
            })}
          </div>
        </div>  
      </div>
    )
  }

}

export default BoxAree