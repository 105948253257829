import React from 'react'
// import { Link } from 'gatsby'


class FeaturedNews extends React.Component {
  constructor(props){
    super(props);
    this.state = { show: false };
    this.hide = this.hide.bind(this);
    this.close = this.close.bind(this);
  }
  hide(){
    sessionStorage.setItem('featured-modal-news-hidden', true);
    this.removeListener();
    this.setState({ hideTimeout: setTimeout(() => this.close(), 1000) });
  }
  close(){
    this.setState({ show: false });
  }
  componentDidMount(){
    if (!sessionStorage.getItem('featured-modal-news-hidden')) {
      this.setState({ show: true });
      this.scrollListener = () => this.hide();
      document.addEventListener('scroll', this.scrollListener);
    }
  }
  removeListener(){
    if (this.scrollListener) {
      document.removeEventListener('scroll', this.scrollListener);
    }
  }
  componentWillUnmount(){
    clearTimeout(this.state.hideTimeout);
    this.removeListener();
  }
  render(){
    const { titolo, testo, link } = this.props.data || {};
    const { show, hideTimeout } = this.state;
    return (
      show ?
      <div className={`featured-news${hideTimeout ? ' featured-news--hiding' : ''}`}>
        <div className="featured-news__content">
          <button disabled={hideTimeout} className="featured-news__close" onClick={this.hide}>
            <svg data-prefix="fal" data-icon="times" role="img" viewBox="0 0 320 512">
              <path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path>
            </svg>
          </button>
          <a href={link}>
            <h3>{titolo}</h3>
          </a>
          <p>{testo}</p>
          <a href={link}>Leggi tutto</a>
        </div>
      </div> : null
    );
  }
};

export default FeaturedNews;